
<template>
  <div>
    <FormAnswer :short_url_id="short_url_id" preview="true"></FormAnswer>
  </div>
</template>

<script>
import FormAnswer from './FormAnswer'

export default {
  components: {
    FormAnswer,
  },
  props: {
    short_url_id: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  methods: {},
  beforeMount() {},
}
</script>

<style>
</style>
